import {path1, path2, path3, pathIwm, pathUni, pathHeader} from './logo.js'

$(document).ready(function() {

    // navbar burger menu
    //--------------------
    $('.navbar-burger').click(function() {
        $('.navbar-burger').toggleClass('is-active')
        $('.navbar-menu').toggleClass('is-active')
    })

    // logo
    //--------------------
    gsap.timeline({defaults: {duration: 1}})
        .to('#logo-part-1', {morphSVG: pathIwm, fill: '#ff5d1f'}, 4)
        .to('#logo-part-2', {morphSVG: pathUni, fill: '#b9283e'}, 4)
        .to('#logo-part-3', {morphSVG: pathHeader, fill: '#3c3c3c'}, 4)
        .to('#logo-part-1', {morphSVG: path1, fill: '#6bb236'}, 9)
        .to('#logo-part-2', {morphSVG: path2, fill: '#6bb236'}, 9)
        .to('#logo-part-3', {morphSVG: path3, fill: '#6bb236'}, 9)

    // carousel
    //--------------------
    $('.owl-carousel').owlCarousel({
        center: false,
        items: 1,
        loop: true,
        stagePadding: 0,
        smartSpeed: 1000,
        autoplay: true,
        pauseOnHover: false,
        dots: false,
        nav: true,
        navText: ['<i class="fas fa-angle-left fa-2x" aria-hidden="true"></i>', '<i class="fas fa-angle-right fa-2x" aria-hidden="true"></i>'],
        navContainer: '.custom-nav',
        animateOut: 'fadeOut',
        animateIn: 'fadeIn'
    })

    // parallax header
    //--------------------
    const siteStellar = function() {
        $(window).stellar({
            responsive: false,
            parallaxBackgrounds: true,
            parallaxElements: true,
            horizontalScrolling: false,
            hideDistantElements: false,
            scrollProperty: 'scroll'
        })
    }
    siteStellar()

    // targets
    //--------------------
    let targetsExecuted = false
    $('.targets svg').waypoint({
        handler: function(direction) {
            if (!targetsExecuted) {
                gsap.to('svg .circle', {
                    duration: 3,
                    scale: 1,
                    ease: 'elastic.out(1, 0.5)',
                    stagger: 0.1
                })
                gsap.to('#lines path', {
                    duration: 3,
                    delay: 2,
                    stagger: 0.1,
                    drawSVG: true
                })
                targetsExecuted = true
            }
        },
        offset: '60%'
    })

    $('.targets .project-tile').mouseover(function() {
        const src = $(this).attr('src')

        gsap.to(this, {
            duration: 0.75,
            opacity: 1,
            '-webkit-filter': 'grayscale(0%)',
            filter: 'grayscale(0%)'
        })

        const elements = $('.targets .project-tile').filter(function() {
            return $(this).attr('src') !== src
        }).get()
        
        gsap.to(elements, {
            duration: 0.75,
            opacity: 0.25,
            '-webkit-filter': 'grayscale(100%)',
            filter: 'grayscale(100%)'
        })
    })

    $('.targets .project-tile').mouseout(function() {
        gsap.to(this, {
            duration: 0.75,
            opacity: 1,
            '-webkit-filter': 'grayscale(0%)',
            filter: 'grayscale(0%)'
        })
    })

    $('.targets .project-tiles').mouseleave(function() {
        gsap.to('.targets .project-tile', {
            duration: 0.75,
            opacity: 1,
            '-webkit-filter': 'grayscale(0%)',
            filter: 'grayscale(0%)'
        })
    })

    // greetings
    //--------------------
    $('.greetings .card').mouseenter(function() {
        gsap.to(this, {
            boxShadow: '0 30px 60px -5px rgba(68, 68, 68, 0.8)',
            duration: .8
        })
    })

    $('.greetings .card').mouseleave(function() {
        gsap.to(this, {
            boxShadow: '0 5px 40px -5px rgba(68, 68, 68, 0.2)',
            duration: .8
        })
    })

    // keynote
    //--------------------
    $('.keynote .card').mouseenter(function() {
        gsap.to(this, {
            boxShadow: '0 30px 60px -5px rgba(68, 68, 68, 0.8)',
            duration: .8
        })
    })

    $('.keynote .card').mouseleave(function() {
        gsap.to(this, {
            boxShadow: '0 5px 40px -5px rgba(68, 68, 68, 0.2)',
            duration: .8
        })
    })

    $('.keynote a.card-footer-item').click(function() {
        const id = $(this).data('id')
        $(`#${id}`).addClass('is-active')
    })

    // project filter
    //--------------------
    $('.tabs li').click(function() {
        $(this).addClass('is-active')
        $(this).siblings().removeClass('is-active')

        $('.projects .column').removeAttr('data-aos data-aos-delay')

        const type = $(this).data('type')
        if (type === 'all') {
            $('.projects .column').css('display', 'block')
        } else {
            $('.projects .column').each(function() {
                const cardTypes = $(this).find('.card').data('type').split(/,/)
                if (cardTypes.includes(type)) {
                    $(this).css('display', 'block')
                } else {
                    $(this).css('display', 'none')
                }
            })
        }
    })

    // projects
    //--------------------
    $('.projects .card').mouseenter(function() {
        gsap.to(this, {
            boxShadow: '0 30px 60px -5px rgba(68, 68, 68, 0.8)',
            duration: .8
        })
    })

    $('.projects .card').mouseleave(function() {
        gsap.to(this, {
            boxShadow: '0 5px 40px -5px rgba(68, 68, 68, 0.2)',
            duration: .8
        })
    })

    $('.projects .card').click(function() {
        const id = $(this).data('id')
        $(`.modal[data-id="${id}"]`).addClass('is-active')
    })

    $('.modal .modal-background, .my-modal-close').click(function() {
        if (!$(this).hasClass('login')) {
            $('.modal').removeClass('is-active')
            stopAllVideos()
        }
    })

    Mousetrap.bind(['esc'], function(event) {
        $('.my-modal-close').trigger('click')
    })

    $('.my-project-modal .card-footer a').mouseenter(function() {
        gsap.to(this, {
            color: '#6bb236',
            duration: .25
        })
    })

    $('.my-project-modal .card-footer a').mouseleave(function() {
        gsap.to(this, {
            color: '#000',
            duration: .25
        })
    })

    $('.keynote .card-content a').click(function() {
        const id = $(this).data('id')
        $(`div[id="${id}"]`).addClass('is-active')
    })

    // footer
    //--------------------
    $('.footer svg').mouseover(function() {
        const id = $(this).attr('id')
        let fill = '#fff'
        if (id === 'iwm') {
            fill = '#ff5d1f'
        } else if (id === 'leibniz') {
            fill = '#025498'
        } else if (id === 'uni-tuebingen') {
            fill = '#b9283e'
        } else if (id === 'uni-stuttgart') {
            fill = '#03b7fa'
        }
        gsap.to(this.querySelectorAll('path'), {
            fill
        })
    })

    $('.footer svg').mouseout(function() {
        gsap.to('.footer path', {
            fill: '#fff'
        })
    })

    // AOS
    //--------------------
    // setTimeout(() => {

        AOS.init({
            duration: 1000,
            easing: 'slide',
            once: false
        })
        
    // }, 100)

    // plyr
    // only allow one player instance at the same time
    //--------------------
    let players = []

    try {
        players = $('.plyr__video-embed').map(function() {
            const plyr = new Plyr(this, {
                controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'pip', 'airplay', 'fullscreen']
            })
            plyr.__buder = $(this).parent().hasClass('buder')
            return plyr
        }).get()

        for (let player of players) {
            player.on('playing', event => {
                players.filter(it => it.id !== player.id).forEach(it => it.pause())
            })
        }
    } catch (error) {}

    function stopAllVideos() {
        for (let player of players) {
            player.pause()
        }
    }

    // marks for buder
    $('.keynote a[data-time]').click(function() {
        const filteredPlayers = players.filter(it => it.__buder)
        if (filteredPlayers.length > 0) {
            filteredPlayers[0].play()
            filteredPlayers[0].currentTime = $(this).data('time')
        }
    })
})
